<template>
  <div>
    <v-card v-if="file" outlined="">
      <v-list
        ><v-list-item
          ><v-list-item-icon
            ><v-icon color="red lighten-3"
              >mdi-file-check-outline</v-icon
            ></v-list-item-icon
          ><v-list-item-content>{{ file.name }}</v-list-item-content
          ><v-list-item-action>
            <v-btn color="error" icon @click="clearFiles"
              ><v-icon>mdi-close</v-icon></v-btn
            ></v-list-item-action
          ></v-list-item
        >
      </v-list>
    </v-card>
    <vue-dropzone
      v-else
      id="dropzone"
      ref="vueDropzone"
      :options="dropzoneOptions"
      :use-custom-slot="true"
      @vdropzone-file-added="previewFile"
    >
      <v-icon large color="green accent-4">mdi-cloud-upload-outline</v-icon>
      <h3>{{ $t('document.clickHere') }}</h3>
      <div class="subtitle">
        {{ $t('document.orSelect') }}
      </div>
    </vue-dropzone>
    <v-card v-if="url" class="mt-4" outlined>
      <v-card-title>{{ $tc('document.name', 1) }}</v-card-title>
      <div class="iframe-container">
        <iframe v-if="url" :src="url">
          This browser does not support PDFs. Please download the PDF to view
          it: Download PDF
        </iframe>
      </div>
    </v-card>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
  components: {
    vueDropzone
  },
  data() {
    return {
      file: null,
      url: '',
      dropzoneOptions: {
        url: 'autoProcessQueueisFalse',
        autoProcessQueue: false,
        addRemoveLinks: true,
        acceptedFiles: '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'
      }
    }
  },
  watch: {
    file(value) {
      value = value ? [value] : []
      this.$store.dispatch('document/setFiles', value)
    }
  },
  methods: {
    previewFile(file) {
      this.file = file
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function() {
          this.url = reader.result
        }.bind(this),
        false
      )
      if (file) {
        this.$refs.vueDropzone.processQueue()
        if (/\.(pdf)$/i.test(file.name)) reader.readAsDataURL(file)
      }
    },
    clearFiles() {
      this.file = null
      this.url = null
    }
  }
}
</script>

<style scoped>
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 50%;
  background: lightgrey;
}

.iframe-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
