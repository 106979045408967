<template>
  <v-card flat>
    <v-card-title>{{ action }} {{ $tc('document.name', 1) }}</v-card-title>
    <v-card-text>
      <BaseCombobox
        v-if="document"
        v-model="document.attributes.name"
        :label="$t('common.name')"
        :items="documentsList"
      />
      <dropzone v-if="!documentId" />
    </v-card-text>
    <!-- Button Actions -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined light @click="$router.go(-1)">{{
        $t('actions.cancel')
      }}</v-btn>
      <v-btn
        :disabled="!files.length && !documentId"
        color="primary"
        depressed
        @click="onSubmit"
        >{{ $t('actions.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Dropzone from './components/Dropzone.vue'
import { DOCUMENT } from '@/models/document'
import { DOCUMENTS_NAME } from '@/common/terms'
import { DocumentMixin } from '@/mixins/document.mixin.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Dropzone
  },
  mixins: [DocumentMixin],
  props: {
    documentId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      documentsList: DOCUMENTS_NAME,
      document: DOCUMENT(),
      category: 'Attachment',
      categories: [
        'FSC CoC Certificate',
        'Control of Legality',
        'Taxes/Royalties',
        'Permits/Licenses',
        'Forest Management Plan'
      ]
    }
  },
  computed: {
    ...mapGetters('document', ['files']),
    action() {
      return this.$t(this.facilityId ? 'actions.edit' : 'actions.create')
    }
  },
  async created() {
    this.categories = await this.fetchDocumentCategories()

    if (this.documentId) {
      this.document = await this.fetchDocument()
      this.category = this.document.attributes.category
    } else {
      this.createFreshDocumentObject(
        this.$route.query.resourceId,
        this.$route.query.resourceType,
        this.category
      )
    }
  },
  methods: {
    ...mapActions({
      addNotification: 'notification/add'
    }),
    async fetchDocumentCategories() {
      return await this.$store.dispatch('terms/fetch', '/documents/types')
    },
    async fetchDocument() {
      return await this.$store.dispatch('document/fetch', this.documentId)
    },
    async onSubmit() {
      try {
        const action = this.documentId ? 'update' : 'create'
        const fn = this.documentId ? 'update' : 'createWithAttachment'
        Object.assign(this.document.attributes, { category: this.category })
        if (this.category) {
          await this.$store.dispatch(`document/${fn}`, this.document)
          this.addNotification({
            status: '200',
            color: 'success',
            time: 2000,
            messages: { title: `Successfully ${action}d` }
          })
          this.$router.go(-1)
        } else alert('Please fill document category before submit')
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    }
  }
}
</script>
